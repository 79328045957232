import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    beforeMount(el, binding) {
      el.clickOutsideEvent = function(event) {
        // Check that the binding.arg contains the class name of the element that should be excluded from processing. 
        // If such an argument is present, then we check whether the clicked element has such a class.
        if (binding.arg && event.target.classList && event.target.classList.contains(binding.arg)) {
            return;
        }
        if (!(el === event.target || el.contains(event.target))) {
            binding.value(event);
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.removeEventListener('click', el.clickOutsideEvent);
    },
  })
});