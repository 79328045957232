import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('highlight-text', {
    beforeMount(el, binding) {
      el.hightlightTextEvent = function() {
        const searchValueForRegex = binding.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const searchRegEx = new RegExp(searchValueForRegex + "(?!([^<]+)?<)", "gi");
        const cssClassName = binding.arg && typeof binding.arg === "string" ? binding.arg : "search-match";

        el.innerHTML = el.innerText.replace(searchRegEx, (match: string) => {
          match = match.replace(/\s/gi, "&nbsp");
          return `<mark class='${cssClassName}'>${match}</mark>`;
        });
      };
      
      el.hightlightTextEvent();
    },
  })
});