import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { defineNuxtPlugin, useRuntimeConfig } from "nuxt/app";
import { RuntimeConfig } from "nuxt/schema";

interface IAppInsightTrackEvent {
    name: string;
    properties?: {[key: string]: string};
    measurements?: {[key: string]: number};
}

export default defineNuxtPlugin(() => {
    const runtimeConfig: RuntimeConfig = useRuntimeConfig();
    const { appInsightConnectionString } = runtimeConfig.public;
    if(!appInsightConnectionString) return {
        provide: { 
            trackPageView: () => {},
            trackEvent: () => {},
        }
    };
    const appInsights = new ApplicationInsights({ config: {
        connectionString: appInsightConnectionString
    }});

    appInsights.loadAppInsights();
    appInsights.trackPageView();
    appInsights.trackEvent();

    return {
        provide: { 
            trackPageView: appInsights.trackPageView as () => void,
            trackEvent: appInsights.trackEvent as IAppInsightTrackEvent,
        }
    };
});

export type { IAppInsightTrackEvent };