import { default as indexfgJaxftl1gMeta } from "/home/vsts/work/1/source/frontend/pages/index.vue?macro=true";
import { default as indexiVvF3au676Meta } from "/home/vsts/work/1/source/frontend/pages/interaction/[id]/index.vue?macro=true";
import { default as indexnCWfIZeCajMeta } from "/home/vsts/work/1/source/frontend/pages/interactions/index.vue?macro=true";
import { default as indexfmZ5EDJ4zWMeta } from "/home/vsts/work/1/source/frontend/pages/landing/index.vue?macro=true";
import { default as indexUcCLsLcSUVMeta } from "/home/vsts/work/1/source/frontend/pages/maintenance/index.vue?macro=true";
import { default as indexttrTHrMQhJMeta } from "/home/vsts/work/1/source/frontend/pages/success-intelligence/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexfgJaxftl1gMeta || {},
    component: () => import("/home/vsts/work/1/source/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "interaction-id",
    path: "/interaction/:id()",
    meta: indexiVvF3au676Meta || {},
    component: () => import("/home/vsts/work/1/source/frontend/pages/interaction/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "interactions",
    path: "/interactions",
    meta: indexnCWfIZeCajMeta || {},
    component: () => import("/home/vsts/work/1/source/frontend/pages/interactions/index.vue").then(m => m.default || m)
  },
  {
    name: "landing",
    path: "/landing",
    meta: indexfmZ5EDJ4zWMeta || {},
    component: () => import("/home/vsts/work/1/source/frontend/pages/landing/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexUcCLsLcSUVMeta || {},
    component: () => import("/home/vsts/work/1/source/frontend/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "success-intelligence",
    path: "/success-intelligence",
    meta: indexttrTHrMQhJMeta || {},
    component: () => import("/home/vsts/work/1/source/frontend/pages/success-intelligence/index.vue").then(m => m.default || m)
  }
]