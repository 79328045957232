import { defineNuxtRouteMiddleware, useRuntimeConfig, navigateTo} from "nuxt/app";

export default defineNuxtRouteMiddleware((to) => {
    const isMaintenanceMode = String(useRuntimeConfig().public.isMaintenanceMode).toLowerCase();
    const maintenanceModeRoute = '/maintenance';
    const navigateToOptions = { redirectCode: 301 };

    if (isMaintenanceMode === 'false' && to.path.includes(maintenanceModeRoute)) {
        return navigateTo('/', navigateToOptions);
    }
    else if (isMaintenanceMode === 'true' && !to.path.includes(maintenanceModeRoute)) {
        return navigateTo(maintenanceModeRoute, navigateToOptions);
    }

    return;
})