import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin(() => {
  const runtimeConfig: RuntimeConfig = useRuntimeConfig();
  let config = runtimeConfig.public.beamer;
  const fullName = localStorage.getItem("userFullName") || "";
  const [firstName, lastName] = fullName.split(" ");
  const userId = localStorage.getItem("userId") || "";
  const customerId = localStorage.getItem("customerId") || "";
  if (config.branding) {
    const script = document.createElement("script");
    script.src = "https://app.getbeamer.com/js/beamer-embed.js";
    script.defer = true;

    const configScript = document.createElement("script");
    configScript.innerHTML = `
      var beamer_config = {
        product_id: '${config.productId}',
        user_id: '${""+ customerId +"-"+ userId}',
        userid: '${userId}',
        user_firstname: '${firstName}',
        user_lastname: '${lastName}',
        customerid: '${customerId}',
      };
    `;

    document.head.appendChild(configScript);
    document.head.appendChild(script);
  }
});
