import validate from "/home/vsts/work/1/source/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/home/vsts/work/1/source/frontend/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/home/vsts/work/1/source/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/vsts/work/1/source/frontend/middleware/auth.ts"),
  granularity: () => import("/home/vsts/work/1/source/frontend/middleware/granularity.ts")
}